import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import queryApi from "hooks/queryApi";
import { IRight } from "modules/Interfaces/IRight";
import { useParams } from "react-router";
import { Method, RightFor } from "hooks/variables";
import { IUser } from "modules/Interfaces/IUser";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Grid, TextField } from "@material-ui/core";
import RightModal from "modules/Modal/RightModal";
import Hierarchy from "../CollapsibleHierarchy/Hierarchy";
import { IEntity } from "modules/Interfaces/IEntity";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: { marginBottom: 20 },
  UserlistSelect: {
    width: 500,
    marginBottom: "2rem",
  },
  pageTitle: { textAlign: "left", fontSize: "1.5rem", marginBottom: "1rem" },
  btnReturn: {
    width: "20rem",
    margin: "0 0 1em 0 !important",
    float: "right",
  },
  icon: { cursor: "pointer" },
});

const TitleContainer = styled.div`
  margin: 1.5rem 0 1rem 0;
  float: left;
`;

const defaultUser: IUser = {
  blockdate: new Date(),
  creationdate: new Date(),
  firstconnexiondate: new Date(),
  id: 0,
  idPerson: 0,
  idWorkcontext: 0,
  is2faenabled: false,
  isautomaticblock: false,
  isdeleted: false,
  islocked: false,
  lastconnexiondate: new Date(),
  lastconnexionidsite: 0,
  lastconnexionlanguage: "",
  lastmodificationdate: new Date(),
  lastpasswordmodificationdate: new Date(),
  login: "",
  nbretry: 0,
};

export default function UserRights() {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState<IUser>(defaultUser);
  const [error, setError] = useState(null);
  const { iduser } = useParams() as { iduser: number | undefined };
  const [usersList, setUsersList] = useState<IUser[] | null>(null);
  const [openRightModal, setOpenRightModal] = useState<boolean>(false);
  const [
    selectedRightForEdit,
    setSelectedRightForEdit,
  ] = useState<IRight | null>(null);
  const [entitiesTreeView, setEntitiesTreeView] = React.useState<
    IEntity[] | null
  >(null);

  useEffect(() => {
    // Check si userid en param -> affectation au user
    console.log(iduser);
    if (iduser !== undefined && iduser > 0) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/User/${iduser}/Light`,
        null,
        Method.Get
      )
        .then((result: any) => {
          setSelectedUser({
            ...selectedUser,
            id: result.id,
            login: result.login,
          });
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    // Chargement de la liste
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/user`,
      null,
      Method.Get
    )
      .then((result: any) => {
        return setUsersList(result);
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadUserRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, iduser]);

  const loadUserRights = () => {
    setEntitiesTreeView(null);
    if (selectedUser !== null && selectedUser.id > 0) {
      // Charge le treeview des entités // TODO spinner
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/user/${selectedUser.id}/treeWithRights`,
        null,
        Method.Get
      )
        .then((result: any) => {
          setEntitiesTreeView(result);
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const handleChangeSelectedUser = (
    _event: React.ChangeEvent<{}>,
    user: IUser | null
  ) => {
    if (user !== null && user.id > 0) {
      setSelectedUser(user);
    }
  };

  const handleCloseRightModal = () => {
    setSelectedRightForEdit(null);
    setOpenRightModal(false);
  };

  const handleSaveRightModal = (right: IRight) => {
    if (right !== undefined && selectedUser.id > 0) {
      right.idUser = selectedUser.id;
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/right`,
        right,
        Method.Post
      )
        .then((result: any) => {
          loadUserRights();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  return error ? (
    <TitleContainer>
      Une erreur est survenue lors de l'appel API!
    </TitleContainer>
  ) : (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={9} className={classes.pageTitle}>
          Droit d'un utilisateur
        </Grid>
      </Grid>
      {usersList !== null ? (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={7}>
            <Autocomplete
              className={classes.UserlistSelect}
              key={Math.random().toString(36).substr(2, 9)}
              noOptionsText="-"
              selectOnFocus
              id="users-list"
              options={usersList}
              getOptionLabel={(option) =>
                option.login + (option.id > 0 ? " - " + option.id : "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Sélectionnez un utilisateur"
                />
              )}
              value={selectedUser}
              onChange={handleChangeSelectedUser}
            />
          </Grid>
          <Grid item xs={5}>
            {selectedUser.id > 0 ? (
              <button
                className={classes.btnReturn}
                onClick={() => setOpenRightModal(true)}
              >
                Ajouter un droit
              </button>
            ) : null}
          </Grid>
          {openRightModal ? (
            <RightModal
              key={Math.random().toString(36).substr(2, 9)}
              selectedRight={selectedRightForEdit}
              openDialog={openRightModal}
              onClose={handleCloseRightModal}
              onSave={handleSaveRightModal}
              onUpdate={null}
              rightFor={RightFor.User}
            />
          ) : null}
        </Grid>
      ) : (
        <CircularProgress />
      )}
      {selectedUser.id !== 0 ? (
        entitiesTreeView !== null ? (
          <Hierarchy rightEntities={entitiesTreeView} reload={loadUserRights} />
        ) : (
          <CircularProgress />
        )
      ) : null}
    </React.Fragment>
  );
}
