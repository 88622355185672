export enum Method {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
}

export enum RightFor{
  Group="GROUP",
  User="USER",
  Entity="ENTITY"
}

export enum ListType{
  Utilisateur="utilisateur",
  Groupe="groupe",
}