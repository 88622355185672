import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logoImg from "assets/logo.png";
import Menu from "modules/Menu/Menu";
import { RightsManagementMenu } from "./menu-data";
import { IMenu } from "./types";
import queryApi from "hooks/queryApi";
import { IMenuAPI } from "modules/Interfaces/IMenuAPI";
import { ITranslation } from "modules/Interfaces/ITranslation";
import { Method } from "hooks/variables";

const Logo = styled.img`
  cursor: pointer;
  padding-top: 0.0625rem;
`;

const Wrapper = styled.div`
  background-color: #2b709f;
  bottom: 0;
  line-height: 0;
  position: fixed;
  top: 0;
  width: 85px;
  z-index: 990;
`;

const SideBar = () => {
  const redirectToMainPage = () => {
    if (process.env.REACT_APP_BASE_URL)
      window.location.href = process.env.REACT_APP_BASE_URL;
  };

  const [menuData, setMenu] = useState<IMenu[] | undefined>(undefined);

  // const [translations, setTranslations] = useState<ITranslation[] | undefined>(
  //   undefined
  // );

  const [error, setError] = useState(null);

  useEffect(() => {
    queryApi(
      `${process.env.REACT_APP_API_TRAD_URL}Translation/${process.env.REACT_APP_TRAD_ID_APP_ENVIRONMENT}/1`,
      null,
      Method.Get
    )
      .then((translationsResult: any) => {
        //setTranslations(translationsResult);

        queryApi(
          `${process.env.REACT_APP_API_TRAD_URL}Menu/${process.env.REACT_APP_TRAD_ID_APP}/${process.env.REACT_APP_TRAD_ID_ENVIRONMENT}`,
          null,
          Method.Get
        )
          .then((result: any) => {
            if (result !== undefined) {
              const parentMenuItem: IMenu[] = [];

              result.forEach((rootMenuItem: IMenuAPI) => {
                if (rootMenuItem.idParentMenuItem === null) {
                  var trad =
                    rootMenuItem?.textKey?.key !== null
                      ? translationsResult?.find(
                          (translation: ITranslation) =>
                            translation.translationKey ===
                            rootMenuItem.textKey.key
                        )?.value
                      : "";

                  parentMenuItem.push({
                    id: rootMenuItem?.idMenuItem,
                    name: trad !== "" ? trad : rootMenuItem.textKey.key,
                    icon:
                      rootMenuItem?.icon !== null
                        ? (!rootMenuItem.icon.startsWith("fa")
                            ? `${process.env.REACT_APP_BO_URL}`
                            : "") + rootMenuItem?.icon
                        : "",
                    childs: [],
                    order: rootMenuItem?.order,
                  });
                  //result.pop(rootMenuItem);
                }
              });

              parentMenuItem.forEach((rootMenuItem: IMenu) => {
                result.forEach((childMenuItem: IMenuAPI) => {
                  if (childMenuItem.idParentMenuItem === rootMenuItem.id) {
                    var trad =
                      childMenuItem?.textKey?.key !== null
                        ? translationsResult?.find(
                            (translation: ITranslation) =>
                              translation.translationKey ===
                              childMenuItem.textKey.key
                          )?.value
                        : "";

                    rootMenuItem.childs.push({
                      id: childMenuItem?.idMenuItem,
                      name: trad !== "" ? trad : childMenuItem.textKey.key,
                      order: childMenuItem?.order,
                      url:
                        `${process.env.REACT_APP_BO_URL}` +
                        childMenuItem?.navigateUrl,
                    });
                  }
                });
              });

              RightsManagementMenu.forEach((menu: IMenu) => {
                //TODO trad
                parentMenuItem.push(menu);
              });

              setMenu(parentMenuItem);
            }
          })
          .catch((error: any) => setError(error));
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return error !== undefined ? (
    <Wrapper>
      <Logo src={logoImg} alt="Logo" onClick={redirectToMainPage} />
      {menuData
        ?.sort((a: any, b: any) => a.order - b.order)
        .map((menu: IMenu) => (
          <Menu key={menu.id} {...menu} />
        ))}
    </Wrapper>
  ) : null;
};

export default SideBar;
