import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import queryApi from "hooks/queryApi";
import { IUser } from "../Interfaces/IUser";
import Checkbox from "@material-ui/core/Checkbox";
import { IGroup } from "modules/Interfaces/IGroup";
import PersonIcon from "@material-ui/icons/Person";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  SwipeableDrawer,
  TablePagination,
  TextField,
} from "@material-ui/core";
import { Method } from "hooks/variables";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ConfirmDialog from "modules/Dialog/ConfirmDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  fullList: {
    width: "auto",
  },
  paper: { marginBottom: 20 },
  pageTitle: { textAlign: "left", fontSize: "1.5rem", marginBottom: "1rem" },
  btnReturn: {
    width: "90%",
    margin: "0 0 1em 0 !important",
    float: "right",
  },
  btnPointer: {
    cursor: "pointer",
  },
  drawerAddUser: {
    minWidth: 600,
  },
  UserlistSelect: {
    maxWidth: "90%",
    marginLeft: "2em !important",
  },
  btnAddUserToGroup: {
    minWidth: "30%",
    float: "right",
    marginRight: "1.8em",
  },
  pagination: { width: "60%" },
  icon: { cursor: "pointer" },
});

const TitleContainer = styled.div`
  margin: 2rem 0 2rem 0;
  float: left;
`;

const TitleDrawer = styled.div`
  margin: 2rem 0 2rem 2rem;
  float: left;
`;

const DescriptionContainer = styled.div`
  float: left;
  font-style: italic;
`;

export default function GroupUsers() {
  const classes = useStyles();
  const [group, setGroup] = useState<IGroup | undefined>(undefined);
  const [groupsUsers, setUsers] = useState<IUser[] | undefined>(undefined);
  const [error, setError] = useState(null);
  const { idgroup } = useParams() as { idgroup: number | undefined };
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState<IUser | null>(
    null
  );
  const [usersList, setUsersList] = useState<IUser[] | null>(null);
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [position, setPosition] = useState({
    right: false,
  });

  useEffect(() => {
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/Group/${idgroup}`,
      null,
      Method.Get
    )
      .then((group: any) => {
        setGroup(group);
        queryApi(
          `${process.env.REACT_APP_API_RIGHTS_URL}api/GroupUsers/${idgroup}/Users`,
          null,
          Method.Get
        )
          .then((users: any) => {
            setUsers(users);
          })
          .catch((error: any) => setError(error));
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = (anchor: string, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setPosition({ ...position, [anchor]: open });
    loadUsersList();
  };

  const handleOpenConfirmDialog = (selectedUserId: number) => {
    if (selectedUserId > 0) {
      var user = groupsUsers?.find((u) => u.id === selectedUserId);

      if (user !== undefined && user !== null) {
        setSelectedUserForEdit(user);
        setOpenConfirmDialog(true);
      }
    }
  };

  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    if (selectedUserForEdit !== null) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/GroupUsers/${group?.id}/User/${selectedUserForEdit.id}`,
        null,
        Method.Delete
      )
        .then((result: any) => {
          history.go(0);
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const loadUsersList = () => {
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/user`,
      null,
      Method.Get
    )
      .then((result: any) => {
        //groupsUsers to remove from result.

        var fullUserlist: IUser[] | null = result;

        var groupsUsersIds = groupsUsers?.map(({ id }) => id);

        const filtered = fullUserlist?.filter(
          (u) => !groupsUsersIds?.includes(u.id)
        );

        if (filtered !== undefined) {
          return setUsersList(filtered);
        }
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleChangeSelectedUser = (
    event: React.ChangeEvent<{}>,
    user: IUser | null
  ) => {
    if (user !== null && user.id > 0) {
      setSelectedUserForEdit(user);
    }
  };

  const drawer = () => {
    return usersList ? (
      <div className={classes.drawerAddUser}>
        <TitleDrawer>
          Ajout d'un utilisateur dans le groupe:{" "}
          <b>
            {group?.name} ({group?.id})
          </b>
        </TitleDrawer>
        <Autocomplete
          className={classes.UserlistSelect}
          key={Math.random().toString(36).substr(2, 9)}
          noOptionsText="-"
          selectOnFocus
          id="users-list"
          options={usersList}
          getOptionLabel={(option) =>
            option.login + (option.id > 0 ? " - " + option.id : "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Sélectionnez un utilisateur"
            />
          )}
          value={selectedUserForEdit}
          onChange={handleChangeSelectedUser}
        />
        <button className={classes.btnAddUserToGroup} onClick={handleAddUser}>
          Valider
        </button>
      </div>
    ) : (
      <div>
        <br></br>
        <CircularProgress />
      </div>
    );
  };

  const handleAddUser = () => {
    if (selectedUserForEdit !== null) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/GroupUsers/${group?.id}/User/${selectedUserForEdit.id}`,
        null,
        Method.Post
      )
        .then((result: any) => {
          history.go(0);
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const handleCancelConfirm = () => {
    setSelectedUserForEdit(null);
    setOpenConfirmDialog(false);
  };

  return error ? (
    <TitleContainer>
      Une erreur est survenue lors de l'appel API!
    </TitleContainer> // TODO page d'erreur
  ) : groupsUsers !== undefined ? (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={9} className={classes.pageTitle}>
          Utilisateur(s) du groupe: {group?.name} ({group?.id})
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={7}>
          <DescriptionContainer>{group?.description}</DescriptionContainer>
        </Grid>
        <Grid item xs={3}>
          <button
            className={classes.btnReturn}
            onClick={toggleDrawer("right", true)}
          >
            Ajouter un utilisateur
          </button>
        </Grid>
        <Grid item xs={2}>
          <button
            className={classes.btnReturn}
            onClick={() => history.push("/GroupList")}
          >
            Retour
          </button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Login</TableCell>
              <TableCell align="center">Verrouillé</TableCell>
              <TableCell align="center">Supprimé</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupsUsers
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((groupUser) => (
                <TableRow key={groupUser.id}>
                  <TableCell align="left">{groupUser.id}</TableCell>
                  <TableCell align="left" scope="row">
                    <a
                      className="userloginLink"
                      href={`${process.env.REACT_APP_BO_URL}/Oxygem/UserList.aspx?user=${groupUser.id}`}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item xs={1}>
                          <Tooltip title="Editer l'utilisateur">
                            <PersonIcon
                              style={
                                groupUser.islocked || groupUser.isdeleted
                                  ? { color: "#e74c3c" }
                                  : { color: "#2ecc71" }
                              }
                              fontSize="large"
                            />
                          </Tooltip>
                        </Grid>
                        <Tooltip title="Editer l'utilisateur">
                          <Grid item xs={1}>
                            {groupUser.login}
                          </Grid>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={10}></Grid>
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox color="primary" checked={groupUser.islocked} />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox color="primary" checked={groupUser.isdeleted} />
                  </TableCell>
                  <TableCell align="center">
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs>
                        <Tooltip title="Voir les droits de l'utilisateur">
                          <VisibilityIcon
                            className={classes.icon}
                            onClick={() =>
                              history.push(`/UserRights/${groupUser.id}`)
                            }
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title="Retirer l'utilisateur du groupe">
                          <DeleteForeverIcon
                            className={classes.btnPointer}
                            onClick={() =>
                              handleOpenConfirmDialog(groupUser.id)
                            }
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Utilisateurs par page"
        className={classes.pagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={groupsUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {openConfirmDialog ? (
        <ConfirmDialog
          key={Math.random().toString(36).substr(2, 9)}
          openConfirmDialog={openConfirmDialog}
          onCancel={handleCancelConfirm}
          onConfirm={handleConfirm}
          text={
            "Etes-vous sûr de vouloir retirer l'utilisateur " +
            selectedUserForEdit?.login +
            " du groupe " +
            group?.name
          }
          title={"Suppression d'un utilisateur du groupe"}
        />
      ) : null}
      {position ? (
        <SwipeableDrawer
          anchor={"right"}
          open={position["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {drawer()}
        </SwipeableDrawer>
      ) : null}
    </React.Fragment>
  ) : (
    <CircularProgress />
  );
}
