import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import queryApi from "hooks/queryApi";
import { IRight } from "modules/Interfaces/IRight";
import Checkbox from "@material-ui/core/Checkbox";
import { useParams } from "react-router";
import { Method, RightFor } from "hooks/variables";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import RightModal from "modules/Modal/RightModal";
import ConfirmDialog from "modules/Dialog/ConfirmDialog";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  pageTitle: { textAlign: "left", fontSize: "1.5rem", marginBottom: "1rem" },
  btnReturn: {
    width: "90%",
    margin: "0 0 1em 0 !important",
    float: "right",
  },
  paper: {
    marginBottom: 30,
  },
  icon: { cursor: "pointer" },
});

const TitleContainer = styled.div`
  margin: 2em 0 2em 0;
  float: left;
`;

export default function EntityRightsManagement() {
  const classes = useStyles();
  const history = useHistory();
  const [rights, setRights] = useState<IRight[]>();
  const [error, setError] = useState(null);
  let { identity } = useParams() as { identity: number | undefined };
  const [openRightModal, setOpenRightModal] = useState<boolean>(false);

  const [
    selectedRightForEdit,
    setSelectedRightForEdit,
  ] = useState<IRight | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    loadRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (index > -1) {
      // 1. Make a shallow copy of the items
      const currentRightsList = rights?.slice();

      if (currentRightsList !== undefined) {
        // 2. Make a shallow copy of the item you want to mutate
        let currentRight: any = currentRightsList[index];

        // 3. Replace the property you're intested in
        if (currentRight !== null && currentRight !== undefined) {
          currentRight[event.target.name] = event.target.checked;
          queryApi(
            `${process.env.REACT_APP_API_RIGHTS_URL}api/Right`,
            currentRight,
            Method.Put
          );
        }

        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        currentRightsList[index] = currentRight;

        // 5. Set the state to our new copy
        setRights(currentRightsList);
      }
    }
  };

  const loadRights = () => {
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/Entity/${identity}/rights`,
      null,
      Method.Get
    )
      .then((rights: any) => {
        setRights(rights);
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleConfirmDelete = () => {
    setOpenConfirmModal(false);

    if (selectedRightForEdit !== null) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/Right`,
        selectedRightForEdit,
        Method.Delete
      )
        .then((result: any) => {
          setSelectedRightForEdit(null);
          loadRights();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const handleCloseRightModal = () => {
    setSelectedRightForEdit(null);
    setOpenRightModal(false);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmModal(false);
    setSelectedRightForEdit(null);
  };

  const handleUpdateRightModal = (right: IRight) => {
    if (right !== undefined && right.id > 0) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/right`,
        right,
        Method.Put
      )
        .then((result: any) => {
          loadRights();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const handleSaveRightModal = (right: IRight) => {
    if (right !== undefined && identity !== undefined) {
      right.idEntity = identity;
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/right`,
        right,
        Method.Post
      )
        .then((result: any) => {
          loadRights();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const handleOpenEditModal = (selectedRightId: number) => {
    if (selectedRightId > 0) {
      let right = rights?.find((r) => r.id === selectedRightId);

      if (right !== undefined && right !== null) {
        setOpenRightModal(true);
        setSelectedRightForEdit(right);
      }
    }
  };

  const handleOpenConfirmDialog = (selectedRightId: number) => {
    if (selectedRightId > 0) {
      let right = rights?.find((r) => r.id === selectedRightId);

      if (right !== undefined && right !== null) {
        setOpenConfirmModal(true);
        setSelectedRightForEdit(right);
      }
    }
  };

  return error ? (
    <TitleContainer>
      Une erreur est survenue lors de l'appel API!
    </TitleContainer> // TODO page d'erreur
  ) : rights ? (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={7} className={classes.pageTitle}>
          L'entité possède {rights.length} droits.
        </Grid>
        <Grid item xs={3}>
          <button
            className={classes.btnReturn}
            onClick={() => setOpenRightModal(true)}
          >
            Ajouter un droit
          </button>
        </Grid>
        <Grid item xs={2}>
          <button
            className={classes.btnReturn}
            onClick={() => history.push("/EntitiesList")}
          >
            Retour
          </button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Id utilisateur</TableCell>
              <TableCell align="left">Id groupe</TableCell>
              <TableCell align="left">Nom de l'entité</TableCell>
              <TableCell align="left">Create</TableCell>
              <TableCell align="left">Read</TableCell>
              <TableCell align="left">Write</TableCell>
              <TableCell align="left">Delete</TableCell>
              <TableCell align="left">Export</TableCell>
              <TableCell align="left">Import</TableCell>
              <TableCell align="left">Execute</TableCell>
              <TableCell align="left">Custom1</TableCell>
              <TableCell align="left">Custom2</TableCell>
              <TableCell align="left">Custom3</TableCell>
              <TableCell align="left">Custom4</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rights?.map((right, index) => (
              <TableRow
                key={Math.random().toString(36).substr(2, 9) + right.id}
              >
                <TableCell align="left">
                  {right.idUser !== null
                    ? right.idUser +
                      ": " +
                      (right.userLogin.length > 25
                        ? right.userLogin.substring(0, 25)
                        : right.userLogin)
                    : ""}
                </TableCell>
                <TableCell align="left">
                  {right.groupName !== null ? (
                    <Tooltip title={right.groupName}>
                      <span>
                        {right.idGroup !== null ? right.idGroup + ": " : ""}
                        {right.groupName?.length > 20
                          ? right.groupName?.substring(0, 20) + "..."
                          : right.groupName}
                      </span>
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell align="left">{right.entityName}</TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    key={Math.random().toString(36).substr(2, 9) + right.id}
                    name="cancreate"
                    checked={right.cancreate}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="canread"
                    checked={right.canread}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="canwrite"
                    checked={right.canwrite}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="candelete"
                    checked={right.candelete}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="canexport"
                    checked={right.canexport}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="canimport"
                    checked={right.canimport}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="canexecute"
                    checked={right.canexecute}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="custom1"
                    checked={right.custom1}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="custom2"
                    checked={right.custom2}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="custom3"
                    checked={right.custom3}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    color="primary"
                    name="custom4"
                    checked={right.custom4}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Tooltip
                        title="Editer"
                        onClick={() => handleOpenEditModal(right.id)}
                      >
                        <EditIcon className={classes.icon} />
                      </Tooltip>
                    </Grid>
                    <Grid item xs>
                      <Tooltip
                        title="Supprimer"
                        onClick={() => handleOpenConfirmDialog(right.id)}
                      >
                        <DeleteForeverIcon className={classes.icon} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openRightModal ? (
        <RightModal
          key={Math.random().toString(36).substr(2, 9)}
          selectedRight={selectedRightForEdit}
          openDialog={openRightModal}
          onClose={handleCloseRightModal}
          onSave={handleSaveRightModal}
          onUpdate={handleUpdateRightModal}
          rightFor={RightFor.Entity}
        />
      ) : null}
      <ConfirmDialog
        key={Math.random().toString(36).substr(2, 9)}
        openConfirmDialog={openConfirmModal}
        onCancel={handleCloseConfirmDialog}
        onConfirm={handleConfirmDelete}
        text={"Etes-vous sûr de vouloir supprimer ce droit"}
        title={"Suppression d'un droit"}
      />
    </React.Fragment>
  ) : (
    <CircularProgress />
  );
}
