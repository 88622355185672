import styled from "styled-components";

export const Item = styled.li`
  box-sizing: border-box;
  cursor: pointer;
  list-style-type: none;
  margin: 0;
  padding: 5px 20px;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const List = styled.ul`
  color: white;
  font-size: 0.8125rem;
  line-height: 1.6;
  list-style-type: none;
  margin: 0.5rem 0;
  padding: 0;
`;

export const Title = styled.div`
  background-color: #2b709f;
  color: #84c1ea;
  font-size: 0.6875rem;
  padding: 1.125rem 1.25rem;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  background-color: #368cc6;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1) inset;
  left: 5.3125rem; // * SideBar width
  min-width: 12.5rem;
  position: absolute;
  text-align: start;
  top: 0;
  white-space: nowrap;
  z-index: 2;
`;
