import React from "react";
import SideBar from "modules/Menu/SideBar";
import TopBar from "modules/TopBar/TopBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "./App.css";
import GroupList from "modules/Group/GroupList";
import GroupRights from "modules/GroupRights/GroupRights";
import GroupUsers from "modules/GroupUsers/GroupUsers";
import UserRights from "modules/UserRights/UserRights";
import EntitiesList from "modules/EntityRights/EntitiesList";
import AddEntity from "modules/Entity/AddEntity";
import { Switch, Route } from "react-router-dom";
import EntityRightsManagement from "modules/EntityRights/EntityRightsManagement";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      margin: "7rem 30px 30px 7.5rem",
    }
  })
);

const App = () => {
  const classes = useStyles();
  return (
    <div className="App">
      <TopBar />
      <SideBar />
      <div className={classes.mainContainer}>
        <Switch>
          <Route exact path="/" component={GroupList} />
          <Route path="/UserRights/:iduser" component={UserRights} />
          <Route path="/UserRights" component={UserRights} />
          <Route path="/GroupUsers/:idgroup" component={GroupUsers} />
          <Route path="/GroupList" component={GroupList} />
          <Route path="/GroupRights/:idgroup" component={GroupRights} />
          <Route path="/EntitiesList" component={EntitiesList} />
          <Route path="/AddEntity" component={AddEntity} />
          <Route
            path="/EntityRightsManagement/:identity"
            component={EntityRightsManagement}
          />
        </Switch>
      </div>
    </div>
  );
};

export default App;
