import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { IEntity } from "modules/Interfaces/IEntity";
import HierarchyRow from "./HierarchyRow";

interface IHierarchyProps {
  rightEntities: IEntity[] | null;
  reload: () => void;
}

const Hierarchy = ({ rightEntities, reload }: IHierarchyProps) => {
  const handleReload = React.useCallback(() => {
    reload();
  }, [reload]);

  return rightEntities ? (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {rightEntities?.map((node, index) => (
              <HierarchyRow
                key={Math.random().toString(36).substr(2, 9)}
                entity={node}
                reload={handleReload}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  ) : null;
};

export default Hierarchy;
