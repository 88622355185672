import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IEntity } from "modules/Interfaces/IEntity";
import queryApi from "hooks/queryApi";
import { useForm } from "react-hook-form";
import "./styles.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { IEntityType } from "modules/Interfaces/IEntityType";
import { CircularProgress, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Method } from "hooks/variables";

interface Props {
  selectedEntity: IEntity | undefined;
  isDisabled: boolean;
  displayEditBtn: boolean;
  displayDeleteBtn: boolean;
  displayRightsBtn: boolean;
}

const EntityDetails = (props: Props) => {
  const [selectedEntity, setSelectedEntity] = useState<
    Partial<IEntity> | undefined
  >(props.selectedEntity);

  //const { selectedEntity } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: 900,
      },
      treeViewList: {
        minwidth: 250,
        textAlign: "left",
        paddingTop: 10,
        paddingLeft: 20,
      },
      detailsPart: {},
      container: {},
      formControl: {
        minWidth: 300,
        marginTop: 30,
        float: "left",
        width: "100%",
      },
      deleteBtn: {
        background: "#e74c3c",
      },
    })
  );
  const history = useHistory();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [entityApplications, setEntityApplications] = useState<
    IEntity[] | undefined
  >(undefined);
  const [entityTypes, setEntityTypes] = useState<IEntityType[] | undefined>(
    undefined
  );
  const [IsDisable, setIsDisable] = useState<boolean>(props.isDisabled);
  const [entityParents, setEntityParents] = useState<IEntity[] | undefined>(
    undefined
  );
  const [displayEditBtn, setdisplayEditBtn] = useState<boolean>(
    props.displayEditBtn
  );
  const [displayDeleteBtn] = useState<boolean>(props.displayDeleteBtn);
  const [IsApplicationType, setIsApplicationType] = useState<boolean>(false);
  const [displayRightsBtn] = useState<boolean>(props.displayRightsBtn);

  useEffect(() => {
    // Charge la liste des types d'entités
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/entitytype`,
      null,
      Method.Get
    )
      .then((result: any) => {
        setEntityTypes(result);
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Charge la liste des entités de type application
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/entity/type/1`,
      null,
      Method.Get
    )
      .then((result: any) => {
        setEntityApplications(result);
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (selectedEntity === undefined) {
      setIsDisable(false);
      setdisplayEditBtn(false);
      setSelectedEntity({
        name: "",
        idEntityapplication: 0,
        idEntityparent: 0,
        idEntitytype: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { register, handleSubmit } = useForm<FormData>();
  const onSubmit = () => {
    let method: Method;

    if (selectedEntity?.id === undefined || selectedEntity?.id === 0) {
      //Save new entity
      method = Method.Post;
    } else {
      //Update existing entity
      method = Method.Put;
    }

    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/entity`,
      selectedEntity,
      method
    )
      .then((result: any) => {
        if (result !== undefined) {
          history.go(0);
        }
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleChangeDisableForm = (isDisable: boolean) => {
    if (selectedEntity?.idEntityparent !== undefined) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/application/${selectedEntity?.idEntityapplication}/entities`,
        null,
        Method.Get
      )
        .then((result: any) => {
          setEntityParents(result);
          setIsDisable(isDisable); // TODO check
        })
        .catch((error: any) => setError(error));
    } else {
      setIsDisable(isDisable);
    } // TODO check}
  };

  const handleCancelChange = () => {
    setIsDisable(true);
    history.push("/EntitiesList");
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEntity({ ...selectedEntity, name: event.target.value });
  };

  const handleChangeType = (
    event: React.ChangeEvent<unknown>,
    selectedId: number
  ) => {
    if (selectedId === 1) {
      setSelectedEntity({
        ...selectedEntity,
        idEntitytype: selectedId,
        idEntityapplication: 0,
        idEntityparent: 0,
      });
      setIsApplicationType(true);
    } else {
      setSelectedEntity({
        ...selectedEntity,
        idEntitytype: selectedId,
      });
    }
  };

  const handleChangeApplication = (
    event: React.ChangeEvent<unknown>,
    selectedId: number
  ) => {
    setSelectedEntity({
      ...selectedEntity,
      idEntityapplication: selectedId,
    });

    if (selectedId !== undefined) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/application/${selectedId}/entities`,
        null,
        Method.Get
      )
        .then((result: any) => {
          setEntityParents(result);
        })
        .catch((error: any) => setError(error));
    }
  };

  const handleChangeParent = (
    event: React.ChangeEvent<unknown>,
    selectedId: number
  ) => {
    setSelectedEntity({
      ...selectedEntity,
      idEntityparent: selectedId,
    });
  };

  const handleDelete = () => {
    console.log("TODO delete"); //TODO
  };

  return error !== undefined ? (
    entityApplications && entityTypes ? (
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <TextField
          id="entityName"
          label="Nom"
          placeholder="Saisissez un nom"
          required
          onChange={handleChangeName}
          variant="standard"
          className={classes.formControl}
          value={
            selectedEntity?.name !== undefined && selectedEntity?.name !== null
              ? selectedEntity.name
              : ""
          }
          disabled={IsDisable}
        />
        {selectedEntity?.id !== undefined ? (
          <React.Fragment>
            <TextField
              id="entityId"
              label="Identifiant"
              variant="standard"
              className={classes.formControl}
              value={selectedEntity?.id}
              disabled
            />
            <TextField
              id="entityNbChild"
              label="Nombre d'enfant(s)"
              variant="standard"
              className={classes.formControl}
              value={
                selectedEntity?.nbChild !== undefined
                  ? selectedEntity.nbChild
                  : 0
              }
              disabled
            />
          </React.Fragment>
        ) : undefined}
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="ChangeTypeSelectLabel">Type</InputLabel>
          <Select
            labelId="ChangeTypeSelectLabel"
            id="ChangeTypeSelect"
            required
            value={selectedEntity?.idEntitytype}
            onChange={(event) =>
              handleChangeType(event, event.target.value as number)
            }
            label="Type"
            disabled={IsDisable}
          >
            {entityTypes?.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="ChangeApplicationSelectLabel">Application</InputLabel>
          <Select
            labelId="ChangeApplicationSelectLabel"
            id="ChangeApplicationSelect"
            value={selectedEntity?.idEntityapplication}
            onChange={(event) =>
              handleChangeApplication(event, event.target.value as number)
            }
            label="Application"
            disabled={IsDisable || IsApplicationType}
          >
            {entityApplications?.map((application) => (
              <MenuItem key={application.id} value={application.id}>
                {application.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {IsDisable === true ? (
          // En création
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="ChangeParentSelectLabel">Parent</InputLabel>
            <Select
              labelId="ChangeParentSelectLabel"
              id="ChangeParentSelect"
              value={selectedEntity?.idEntityparent}
              label="Parent"
              autoWidth
              disabled={IsDisable || IsApplicationType}
            >
              <MenuItem value={selectedEntity?.idEntityparent}>
                {selectedEntity?.parentName}
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          // En édition + liste chargée
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="ChangeParentSelectLabel">Parent</InputLabel>
            <Select
              labelId="ChangeParentSelectLabel"
              id="ChangeParentSelect"
              value={selectedEntity?.idEntityparent}
              onChange={(event) =>
                handleChangeParent(event, event.target.value as number)
              }
              label="Parent"
              autoWidth
              disabled={IsDisable || IsApplicationType}
              ref={register}
            >
              {entityParents?.map((parent) => (
                <MenuItem key={parent.id} value={parent.id}>
                  {parent.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div>
          {displayDeleteBtn === true ? (
            <button
              id="deleteBtn"
              // disabled={IsDisable}
              disabled
              className={classes.deleteBtn}
              onClick={() => handleDelete()}
              type="button"
            >
              Supprimer
            </button>
          ) : null}
          {displayRightsBtn === true ? (
            <button
              id="rigthsBtn"
              type="button"
              onClick={() =>
                history.push(`/EntityRightsManagement/${selectedEntity?.id}`)
              }
            >
              Gérer les droits
            </button>
          ) : null}
          {displayEditBtn === true && IsDisable ? (
            <button
              id="editBtn"
              type="button"
              disabled={!IsDisable}
              onClick={() => handleChangeDisableForm(false)}
            >
              Modifier
            </button>
          ) : (
            <button
              id="cancelBtn"
              type="button"
              onClick={() => handleCancelChange()}
            >
              Annuler
            </button>
          )}
          <button type="submit" disabled={IsDisable}>
            Enregistrer
          </button>
        </div>
      </form>
    ) : (
      <CircularProgress />
    )
  ) : null;
};

export default EntityDetails;
