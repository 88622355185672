import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { IEntity } from "modules/Interfaces/IEntity";
import { IRight } from "modules/Interfaces/IRight";
import queryApi from "hooks/queryApi";
import { Method } from "hooks/variables";
import ConfirmDialog from "modules/Dialog/ConfirmDialog";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  icon: { cursor: "pointer" },
  treeviewtext: {
    fontSize: "14px",
    fontFamily: "Open Sans, Helvetica, Roboto, Arial, sans-serif;",
    fontWeight: "lighter",
    lineHeight: 1.5,
    color: "grey",
  },
});

interface IHierarchyRowProps {
  entity: IEntity;
  open?: boolean;
  reload: () => void;
}

const defaultRight: IRight = {
  entityName: "",
  cancreate: false,
  candelete: false,
  canexecute: false,
  canexport: false,
  canimport: false,
  canread: false,
  canwrite: false,
  custom1: false,
  custom2: false,
  custom3: false,
  custom4: false,
  id: 0,
  idEntity: 0,
  idGroup: 0,
  idUser: 0,
  userLogin: "",
  groupName: "",
};

const HierarchyRow = ({
  entity,
  open: defaultOpen,
  reload,
}: IHierarchyRowProps) => {
  const classes = useRowStyles();
  const [open, setOpen] = useState(defaultOpen || false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [currentRight, setCurrentRight] = useState<IRight>(
    entity.right !== undefined ? entity.right : defaultRight
  );

  const handleChangeRights = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedRight = {
      ...currentRight,
      [event.target.name]: event.target.checked,
    };

    setCurrentRight(updatedRight);

    if (updatedRight !== null && updatedRight.id > 0) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/Right`,
        updatedRight,
        Method.Put
      );
    }
  };

  const handleOpenConfirmDialog = () => {
    if (currentRight !== null && currentRight.id > 0) {
      setOpenConfirmModal(true);
    }
  };

  const handleConfirmDelete = () => {
    setOpenConfirmModal(false);
    if (currentRight !== null) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/Right`,
        currentRight,
        Method.Delete
      ).then(() => handleReload());
    }
  };

  const handleReload = React.useCallback(() => {
    reload();
  }, [reload]);

  return (
    <React.Fragment key={Math.random().toString(36).substr(2, 9)}>
      <TableRow className={classes.root}>
        <TableCell width="1rem">
          {Array.isArray(entity.children) && entity.children?.length > 0 ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell component="th" scope="row">
          <label className={classes.treeviewtext}>{entity.name}</label>
        </TableCell>
        {entity.right !== null ? (
          <React.Fragment key={Math.random().toString(36).substr(2, 9)}>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Create</label>
              <Tooltip title="cancreate">
                <Checkbox
                  color="primary"
                  key={Math.random().toString(36).substr(2, 9)}
                  name="cancreate"
                  checked={currentRight.cancreate}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Read</label>
              <Tooltip title="canread">
                <Checkbox
                  color="primary"
                  name="canread"
                  checked={currentRight?.canread}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Write</label>
              <Tooltip title="canwrite">
                <Checkbox
                  color="primary"
                  name="canwrite"
                  checked={currentRight?.canwrite}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Delete</label>
              <Tooltip title="candelete">
                <Checkbox
                  color="primary"
                  name="candelete"
                  checked={currentRight?.candelete}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Export</label>
              <Tooltip title="canexport">
                <Checkbox
                  color="primary"
                  name="canexport"
                  checked={currentRight?.canexport}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Import</label>
              <Tooltip title="canimport">
                <Checkbox
                  color="primary"
                  name="canimport"
                  checked={currentRight?.canimport}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Execute</label>
              <Tooltip title="canexecute">
                <Checkbox
                  color="primary"
                  name="canexecute"
                  checked={currentRight?.canexecute}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Custom1</label>
              <Tooltip title="custom1">
                <Checkbox
                  color="primary"
                  name="custom1"
                  checked={currentRight?.custom1}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Custom2</label>
              <Tooltip title="custom2">
                <Checkbox
                  color="primary"
                  name="custom2"
                  checked={currentRight?.custom2}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Custom3</label>
              <Tooltip title="custom3">
                <Checkbox
                  color="primary"
                  name="custom3"
                  checked={currentRight?.custom3}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              <label className={classes.treeviewtext}>Custom4</label>
              <Tooltip title="custom4">
                <Checkbox
                  color="primary"
                  name="custom4"
                  checked={currentRight?.custom4}
                  onChange={handleChangeRights}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <Tooltip
                    title="Supprimer le droit"
                    onClick={handleOpenConfirmDialog}
                  >
                    <DeleteForeverIcon className={classes.icon} />
                  </Tooltip>
                </Grid>
              </Grid>
            </TableCell>
          </React.Fragment>
        ) : null}
      </TableRow>
      {entity.children !== undefined && entity.children?.length > 0 ? (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "1rem" }}
            colSpan={14}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableHead></TableHead>
                {entity.children?.map((childEntity) => (
                  <TableBody key={Math.random().toString(36).substr(2, 9)}>
                    <HierarchyRow
                      key={Math.random().toString(36).substr(2, 9)}
                      entity={childEntity}
                      reload={handleReload}
                    />
                  </TableBody>
                ))}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
      {openConfirmModal ? (
        <ConfirmDialog
          key={Math.random().toString(36).substr(2, 9)}
          openConfirmDialog={openConfirmModal}
          onCancel={() => setOpenConfirmModal(false)}
          onConfirm={handleConfirmDelete}
          text={"Etes-vous sûr de vouloir supprimer ce droit"}
          title={"Suppression d'un droit"}
        />
      ) : null}
    </React.Fragment>
  );
};

export default HierarchyRow;
