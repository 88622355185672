import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useHoverDirty } from "react-use";
import SubMenu from "./SubMenu";
import { Item } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faQuestion } from "@fortawesome/pro-light-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface MenuItem {
  name?: string;
  icon?: string;
  childs?: Item[];
}

const Icon = styled.img`
  height: 2.5rem;
  padding: 4px 5px 5px 0;
  width: 2.5rem;
`;

const Wrapper = styled.div`
  height: 3.125rem;
  position: relative;
`;

const Menu = (props: MenuItem) => {
  const ref = useRef(null);
  const isHovering = useHoverDirty(ref);
  const [isChildHover, setChildHover] = useState(false);

  React.useEffect(() => {
    if (isHovering && !isChildHover) setChildHover(true);
    else if (!isHovering && isChildHover) setChildHover(false);
  }, [isChildHover, isHovering]);

  const { icon, name, childs } = props;

  const renderMenuIcon = (icon: string | undefined) => {
    let iconDef: IconDefinition;
    switch (icon) {
      case "fal fa-database fa-2x":
        iconDef = faDatabase;
        break;
      default:
        iconDef = faQuestion;
        break;
    }

    return <FontAwesomeIcon icon={iconDef} size="2x" color="white" />;
  };

  return (
    <Wrapper ref={ref}>
      {icon?.startsWith("https://") ? (
        <Icon src={icon} />
      ) : (
        renderMenuIcon(icon)
      )}
      <SubMenu
        isOpen={isHovering}
        isHovering={isChildHover}
        setHover={setChildHover}
        name={name !== undefined ? name : ""}
        childs={childs !== undefined ? childs : []}
      />
    </Wrapper>
  );
};

export default Menu;
