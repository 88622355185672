import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IGroup } from "modules/Interfaces/IGroup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, TextareaAutosize, Theme } from "@material-ui/core";

interface Props {
  selectedGroup: IGroup | null;
  openDialog: boolean;
  onClose: () => void;
  onSave: (group: IGroup) => void;
  onUpdate: (group: IGroup) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textareaCls: {
      width: "99%",
      marginTop: 30,
      // flexDirection: "row",
      // marginLeft: 0,
    },
  })
);

const GroupModal = ({
  openDialog,
  onClose,
  selectedGroup,
  onSave,
  onUpdate,
}: Props) => {
  const classes = useStyles();
  const [openEditDialog, setOpenEditGroupDialog] = useState<boolean>(
    openDialog
  );
  const [currentGroup, setCurrentGroup] = useState<IGroup | null>(
    selectedGroup
  );

  const defaultGroup: IGroup = currentGroup || {
    name: "",
    description: "",
    id: 0,
    creationdate: new Date(),
    isdeleted: false,
    islocked: false,
  };

  const handleClose = React.useCallback(() => {
    setOpenEditGroupDialog(false);
    onClose();
  }, [onClose, setOpenEditGroupDialog]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentGroup({ ...defaultGroup, name: event.target.value });
  };

  const handleChangeDescription = (
    _event: React.ChangeEvent<HTMLTextAreaElement>,
    description: string
  ) => {
    setCurrentGroup({ ...defaultGroup, description });
  };

  const handleSave = React.useCallback(() => {
    if (currentGroup !== null && currentGroup !== undefined) {
      if (currentGroup.id > 0) onUpdate(currentGroup);
      if (currentGroup.id <= 0) onSave(currentGroup);
    }

    onClose();
  }, [onSave, onUpdate, onClose, currentGroup]);

  return (
    <Dialog
      fullWidth={true}
      open={openEditDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {currentGroup !== null
          ? "Edition d'un groupe"
          : "Création d'un nouveau groupe"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nom du groupe"
          type="text"
          fullWidth
          value={currentGroup?.name || ""}
          onChange={handleChangeName}
        />
        <TextareaAutosize
          id="groupDescription"
          placeholder="Description"
          rows={4}
          rowsMax={4}
          className={classes.textareaCls}
          value={currentGroup?.description || ""}
          onChange={(event) =>
            handleChangeDescription(event, event.target.value as string)
          }
        />
        {/* <FormControlLabel
          control={
            <Switch
              size="small"
              checked={selectedGroup?.islocked}
              //onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Verrouillé"
          className={classes.textareaCls}
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={selectedGroup?.isdeleted}
              //onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Supprimé"
          className={classes.textareaCls}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSave} color="primary">
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupModal;
