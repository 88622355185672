import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import queryApi from "hooks/queryApi";
import { IGroup } from "modules/Interfaces/IGroup";
import Moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import GroupModal from "./GroupModal";
import { Method } from "hooks/variables";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ConfirmDialog from "../Dialog/ConfirmDialog";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  icon: { cursor: "pointer" },
  paper: { marginBottom: 20 },
  pageTitle: { textAlign: "left", fontSize: "1.5rem" },
  btnAddGroup: {
    width: "50%",
    margin: "0 0 1em 0 !important",
    float: "right",
  },
});

const TitleContainer = styled.div`
  margin: 1.5rem 0 1rem 0;
  float: left;
`;

export default function SimpleTable() {
  const classes = useStyles();
  const [groupsList, setGroupsList] = useState<IGroup[]>([]);
  const [error, setError] = useState(null);
  const [
    selectedGroupForEdit,
    setSelectedGroupForEdit,
  ] = useState<IGroup | null>(null);
  const history = useHistory();
  const [openEditGroupModal, setOpenEditGroupModal] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    refreshList();
  }, []);

  const handleOpenEditModal = (selectedGroupId: number) => {
    if (selectedGroupId > 0) {
      var group = groupsList?.find((g) => g.id === selectedGroupId);

      if (group !== undefined && group !== null) {
        setOpenEditGroupModal(true);
        setSelectedGroupForEdit(group);
      }
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditGroupModal(false);
    setSelectedGroupForEdit(null);
  };

  function refreshList() {
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/Group`,
      null,
      Method.Get
    )
      .then((result: any) => {
        setGroupsList(result);
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const handleUpdateGroup = (group: IGroup) => {
    if (group !== undefined) {
      group.lastmodificationdate = new Date();
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/Group`,
        group,
        Method.Put
      )
        .then((result: any) => {
          refreshList();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    setSelectedGroupForEdit(null);
  };

  const handleSaveGroup = (group: IGroup) => {
    if (group !== undefined) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/Group`,
        group,
        Method.Post
      )
        .then((result: any) => {
          refreshList();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    refreshList();
  };

  const handleOpenConfirmDialog = (selectedGroupId: number) => {
    if (selectedGroupId > 0) {
      var group = groupsList?.find((g) => g.id === selectedGroupId);

      if (group !== undefined && group !== null) {
        setSelectedGroupForEdit(group);
        setOpenConfirmDialog(true);
      }
    }
  };

  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    if (selectedGroupForEdit !== null) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/Group/${selectedGroupForEdit.id}`,
        null,
        Method.Delete
      )
        .then(() => {
          setSelectedGroupForEdit(null);
          refreshList();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  return error ? (
    <TitleContainer>
      {error}
      Une erreur est survenue lors de l'appel API!
    </TitleContainer> // TODO page d'erreur
  ) : (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={9} className={classes.pageTitle}>
          <TitleContainer>Gestion des groupes</TitleContainer>
        </Grid>
        <Grid item xs={3}>
          <button
            className={classes.btnAddGroup}
            onClick={() => setOpenEditGroupModal(true)}
          >
            Ajouter un groupe
          </button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {groupsList?.map((group) => (
              <TableRow key={group.id} hover>
                <TableCell align="left">{group.id}</TableCell>
                <TableCell align="left" scope="row">
                  {group.name}
                </TableCell>
                <TableCell align="left">{group.description}</TableCell>
                <TableCell align="left">
                  {group.creationdate !== undefined &&
                  group.creationdate !== null
                    ? "Crée le " +
                      Moment(group.creationdate).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
                <TableCell align="left">
                  {group.lastmodificationdate !== undefined &&
                  group.lastmodificationdate !== null
                    ? "MAJ le " +
                      Moment(group.lastmodificationdate).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
                <TableCell align="center">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Tooltip title="Voir les droits du groupe">
                        <VisibilityIcon
                          className={classes.icon}
                          onClick={() =>
                            history.push(`/GroupRights/${group.id}`)
                          }
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs>
                      <Tooltip title="Voir les utilisateurs du groupe">
                        <PeopleIcon
                          className={classes.icon}
                          onClick={() =>
                            history.push(`/GroupUsers/${group.id}`)
                          }
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs>
                      <Tooltip
                        title="Editer"
                        onClick={() => handleOpenEditModal(group.id)}
                      >
                        <EditIcon className={classes.icon} />
                      </Tooltip>
                    </Grid>
                    <Grid item xs>
                      <Tooltip
                        title="Supprimer"
                        onClick={() => handleOpenConfirmDialog(group.id)}
                      >
                        <DeleteForeverIcon className={classes.icon} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <GroupModal
        key={Math.random().toString(36).substr(2, 9)}
        selectedGroup={selectedGroupForEdit}
        openDialog={openEditGroupModal}
        onClose={handleCloseEditModal}
        onSave={handleSaveGroup}
        onUpdate={handleUpdateGroup}
      />
      <ConfirmDialog
        key={Math.random().toString(36).substr(2, 9)}
        openConfirmDialog={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirm}
        text={
          "Etes-vous sûr de vouloir supprimer le groupe: " +
          selectedGroupForEdit?.name
        }
        title={"Suppression d'un groupe"}
      />
    </React.Fragment>
  );
}
