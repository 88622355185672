import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import queryApi from "hooks/queryApi";
import { IRight } from "modules/Interfaces/IRight";
import { IGroup } from "modules/Interfaces/IGroup";
import { useParams } from "react-router";
import { Method, RightFor } from "hooks/variables";
import { CircularProgress, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import RightModal from "modules/Modal/RightModal";
import Hierarchy from "modules/CollapsibleHierarchy/Hierarchy";
import { IEntity } from "modules/Interfaces/IEntity";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  pageTitle: { textAlign: "left", fontSize: "1.5rem", marginBottom: "1rem" },
  btnReturn: {
    width: "90%",
    margin: "0 0 1em 0 !important",
    float: "right",
  },
  icon: { cursor: "pointer" },
});

const TitleContainer = styled.div`
  margin: 1.5rem 0 1rem 0;
  float: left;
`;

const DescriptionContainer = styled.div`
  margin-bottom: 1em;
  float: left;
  font-style: italic;
`;

export default function GroupRights() {
  const classes = useStyles();
  const history = useHistory();
  const [group, setGroup] = useState<IGroup | null>(null);
  const [entitiesTreeView, setEntitiesTreeView] = React.useState<
    IEntity[] | null
  >(null);
  const [error, setError] = useState(null);
  let { idgroup } = useParams() as { idgroup: number | undefined };
  const [openRightModal, setOpenRightModal] = useState<boolean>(false);
  const [
    selectedRightForEdit,
    setSelectedRightForEdit,
  ] = useState<IRight | null>(null);

  useEffect(() => {
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/Group/${idgroup}`,
      null,
      Method.Get
    )
      .then((group: any) => {
        setGroup(group);
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idgroup]);

  useEffect(() => {
    loadGroupRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const loadGroupRights = () => {
    setEntitiesTreeView(null);
    if (group !== null && group.id > 0) {
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/group/${group.id}/treeWithRights`,
        null,
        Method.Get
      )
        .then((result: any) => {
          setEntitiesTreeView(result);
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const handleCloseRightModal = () => {
    setSelectedRightForEdit(null);
    setOpenRightModal(false);
  };

  const handleSaveRightModal = (right: IRight) => {
    if (right !== undefined && idgroup !== undefined) {
      right.idGroup = idgroup;
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/right`,
        right,
        Method.Post
      )
        .then((result: any) => {
          loadGroupRights();
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  return error ? (
    <TitleContainer>
      Une erreur est survenue lors de l'appel API!
    </TitleContainer> // TODO page d'erreur
  ) : (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={9} className={classes.pageTitle}>
          Droits du groupe: {group?.name} ({group?.id})
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={7}>
          <DescriptionContainer>{group?.description}</DescriptionContainer>
        </Grid>
        <Grid item xs={3}>
          <button
            className={classes.btnReturn}
            onClick={() => setOpenRightModal(true)}
          >
            Ajouter un droit
          </button>
        </Grid>
        <Grid item xs={2}>
          <button
            className={classes.btnReturn}
            onClick={() => history.push("/GroupList")}
          >
            Retour
          </button>
        </Grid>
      </Grid>
      {group !== null ? (
        entitiesTreeView !== null ? (
          <Hierarchy
            rightEntities={entitiesTreeView}
            reload={loadGroupRights}
          />
        ) : (
          <CircularProgress />
        )
      ) : null}
      {openRightModal ? (
        <RightModal
          key={Math.random().toString(36).substr(2, 9)}
          selectedRight={selectedRightForEdit}
          openDialog={openRightModal}
          onClose={handleCloseRightModal}
          onSave={handleSaveRightModal}
          onUpdate={null}
          rightFor={RightFor.Group}
        />
      ) : null}
    </React.Fragment>
  );
}
