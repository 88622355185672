import { IMenu } from "./types";

const rights: IMenu = {
  id: 999,
  name: "GESTION DES UTILISATEURS ET DES DROITS",
  icon: "https://bo.mindbaz.com/library/img/noun_users_white.png",
  order: 2,
  childs: [
    {
      id: 1,
      name: "Gestion des comptes",
      order: 2,
      url: `${process.env.REACT_APP_BO_URL}/Oxygem/UserList.aspx`,
    },
    {
      id: 2,
      name: "Gestion des groupes",
      order: 2,
      url: "/GroupList",
    },
    {
      id: 3,
      name: "Gestion des entités",
      order: 3,
      url: "/EntitiesList",
    },
    {
      id: 4,
      name: "Droits des utilisateurs",
      order: 4,
      url: "/UserRights",
    }
  ],
};

export const RightsManagementMenu = [rights];
