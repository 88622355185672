import { Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import EntityDetails from "../EntityRights/EntityDetails";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  formPosition: {
    padding: "20px 0 30px 0 !important",
  },
  paper: {},
  btnReturn: {
    width: "200px",
    margin: "0 0 1em 0 !important",
    float: "right",
  },
});

export default function RecursiveTreeView() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <button
            className={classes.btnReturn}
            onClick={() => history.push("/EntitiesList")}
          >
            Retour
          </button>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <div className={classes.formPosition}>
          <EntityDetails
            selectedEntity={undefined}
            isDisabled={false}
            displayEditBtn={false}
            displayDeleteBtn={false}
            displayRightsBtn={false}
          />
        </div>
      </Paper>
    </React.Fragment>
  );
}
