import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { CircularProgress, Grid } from "@material-ui/core";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { IEntity } from "modules/Interfaces/IEntity";
import queryApi from "hooks/queryApi";
import "./styles.css";
import EntityDetails from "./EntityDetails";
import { useHistory } from "react-router-dom";
import { Method } from "hooks/variables";

const TitleContainer = styled.div`
  margin: 2em 0 2em 0;
  text-align: "left";
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: { textAlign: "left", fontSize: "1.5rem" },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      height: "100%",
      minHeight: 600,
    },
    treeViewList: {
      minwidth: 250,
      textAlign: "left",
      paddingTop: 10,
      paddingLeft: 20,
    },
    detailsPart: {},
    container: { minHeight: 900 },
    formControl: {
      minWidth: 300,
      marginTop: 30,
      float: "left",
    },
    selectLabel: {},
    btnAddEntity: {
      width: "50%",
      margin: "0 0 1em 0 !important",
      float: "right",
    },
  })
);

export default function RecursiveTreeView() {
  const classes = useStyles();
  const history = useHistory();

  const [entitiesTreeView, setEntitiesTreeView] = useState<
    IEntity[] | undefined
  >(undefined);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Charge le treeview des entités // TODO spinner
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/entity/tree`,
      null,
      Method.Get
    )
      .then((result: any) => {
        setEntitiesTreeView(result);
      })
      .catch((error: any) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTree = (node: IEntity) => (
    <TreeItem key={node.id} nodeId={node.id.toString()} label={node.name}>
      {Array.isArray(node.children)
        ? node?.children?.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const [selectedEntity, setSelected] = React.useState<IEntity | undefined>();
  const handleSelect = (
    event: React.ChangeEvent<{}>,
    nodeId: string | undefined
  ) => {
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/entity/${nodeId}`,
      null,
      Method.Get
    )
      .then((result: any) => {
        setSelected(result);
      })
      .catch((error: any) => setError(error));
  };

  return error !== undefined ? (
    entitiesTreeView !== undefined ? (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={9} className={classes.pageTitle}>
            <label>Gestion des entités</label>
          </Grid>
          <Grid item xs={3}>
            <button
              className={classes.btnAddEntity}
              onClick={() => history.push("/addEntity")}
            >
              Ajouter une entité
            </button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className={classes.container}
        >
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <TitleContainer>Liste des entités</TitleContainer>
              <TreeView
                className={classes.treeViewList}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={["root"]}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={handleSelect}
              >
                {entitiesTreeView?.map((entity) => renderTree(entity))}
              </TreeView>
            </Paper>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={7} className={classes.detailsPart}>
            <Paper className={classes.paper}>
              {selectedEntity?.id !== undefined ? (
                <EntityDetails
                  key={selectedEntity.id + "_" + selectedEntity.name}
                  selectedEntity={selectedEntity}
                  isDisabled={true}
                  displayEditBtn={true}
                  displayDeleteBtn={true}
                  displayRightsBtn={true}
                />
              ) : (
                <p>Aucune entité sélectionnée.</p>
              )}
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    ) : (
      <CircularProgress />
    )
  ) : null;
}
