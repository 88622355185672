import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import { IRight } from "modules/Interfaces/IRight";
import { ListType, Method, RightFor } from "hooks/variables";
import queryApi from "hooks/queryApi";
import { IEntity } from "modules/Interfaces/IEntity";
import { IUser } from "modules/Interfaces/IUser";
import { IGroup } from "modules/Interfaces/IGroup";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

interface Props {
  selectedRight: IRight | null;
  openDialog: boolean;
  onClose: () => void;
  onSave: (group: IRight) => void;
  onUpdate: ((group: IRight) => void) | null;
  rightFor: RightFor;
}

const defaultRight: IRight = {
  entityName: "",
  cancreate: false,
  candelete: false,
  canexecute: false,
  canexport: false,
  canimport: false,
  canread: false,
  canwrite: false,
  custom1: false,
  custom2: false,
  custom3: false,
  custom4: false,
  id: 0,
  idEntity: 0,
  idGroup: 0,
  idUser: 0,
  userLogin: "",
  groupName: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textareaCls: {
      width: "99%",
      marginTop: 30,
    },
    formGroup: {
      float: "left",
    },
    formControl: {
      minWidth: 300,
      marginTop: 30,
      marginBottom: 30,
      float: "left",
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const RightModal = ({
  openDialog,
  onClose,
  selectedRight,
  onSave,
  onUpdate,
  rightFor,
}: Props) => {
  const classes = useStyles();
  const [openEditDialog, setOpenEditGroupDialog] = useState<boolean>(
    openDialog
  );
  const [currentRight, setCurrentRight] = useState<IRight>(
    selectedRight !== null ? selectedRight : defaultRight
  );
  const [error, setError] = useState(null);
  const [entities, setEntities] = useState<IEntity[] | null>(null);
  const [radioChoice, setRadioChoice] = useState<ListType>(
    currentRight.idUser === null ? ListType.Groupe : ListType.Utilisateur
  );
  const [usersList, setUsersList] = useState<IUser[] | null>(null);
  const [groupsList, setGroupsList] = useState<IGroup[] | null>(null);

  useEffect(() => {
    if (rightFor === RightFor.User || rightFor === RightFor.Group) {
      // Charge la liste des entités
      queryApi(
        `${process.env.REACT_APP_API_RIGHTS_URL}api/entity`,
        null,
        Method.Get
      )
        .then((result: any) => {
          return setEntities(result);
        })
        .catch((error: any) => setError(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else if (rightFor === RightFor.Entity) {
      switch (radioChoice) {
        case ListType.Utilisateur:
          if (usersList === null) {
            queryApi(
              `${process.env.REACT_APP_API_RIGHTS_URL}api/user`,
              null,
              Method.Get
            )
              .then((result: any) => {
                return setUsersList(result);
              })
              .catch((error: any) => setError(error));
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }
          break;
        case ListType.Groupe:
          if (groupsList === null) {
            queryApi(
              `${process.env.REACT_APP_API_RIGHTS_URL}api/group/light`,
              null,
              Method.Get
            )
              .then((result: any) => {
                return setGroupsList(result);
              })
              .catch((error: any) => setError(error));
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }
          break;
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioChoice]);

  const handleClose = React.useCallback(() => {
    setOpenEditGroupDialog(false);
    onClose();
  }, [onClose, setOpenEditGroupDialog]);

  const handleChangeSelectedEntity = (
    _event: React.ChangeEvent<{}>,
    entity: IEntity | null
  ) => {
    if (entity !== null) {
      setCurrentRight({
        ...currentRight,
        idEntity: entity.id,
      });
    }
  };

  const handleChangeSelectedUser = (
    _event: React.ChangeEvent<{}>,
    user: IUser | null
  ) => {
    if (user !== null) {
      setCurrentRight({
        ...currentRight,
        idUser: user.id,
      });
    }
  };

  const handleChangeSelectedGroup = (
    _event: React.ChangeEvent<{}>,
    group: IGroup | null
  ) => {
    if (group !== null) {
      setCurrentRight({
        ...currentRight,
        idGroup: group.id,
      });
    }
  };

  const handleChangeRights = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentRight({
      ...currentRight,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSave = React.useCallback(() => {
    if (currentRight !== null && currentRight !== undefined) {
      if (currentRight.id > 0 && onUpdate !== null) onUpdate(currentRight);
      if (currentRight.id <= 0) onSave(currentRight);
    }

    onClose();
  }, [onSave, onUpdate, onClose, currentRight]);

  const renderSwitchTitle = () => {
    switch (rightFor) {
      case RightFor.Entity:
        return "Création d'un droit pour l'entité ";
      case RightFor.Group:
        return "Création d'un droit pour le groupe ";
      case RightFor.User:
        return "Création d'un droit pour l'utilisateur ";
    }
  };

  const renderEntityTypeList = () => {
    return entities ? (
      <Autocomplete
        key={Math.random().toString(36).substr(2, 9)}
        noOptionsText="-"
        selectOnFocus
        id="entities-list"
        options={entities}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Sélectionnez une entité"
          />
        )}
        value={
          currentRight !== null && currentRight.idEntity !== 0
            ? entities.find((u) => u.id === currentRight.idEntity)
            : null
        }
        onChange={handleChangeSelectedEntity}
      />
    ) : (
      <div>
        <br></br>
        <CircularProgress />
      </div>
    );
  };

  const renderGroupChoiceList = () => {
    return groupsList ? (
      <Autocomplete
        key={Math.random().toString(36).substr(2, 9)}
        noOptionsText="-"
        selectOnFocus
        id="groups-list"
        options={groupsList}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Sélectionnez un groupe"
          />
        )}
        value={
          currentRight !== null && currentRight.idGroup !== 0
            ? groupsList.find((u) => u.id === currentRight.idGroup)
            : null
        }
        onChange={handleChangeSelectedGroup}
      />
    ) : (
      <div>
        <br></br>
        <CircularProgress />
      </div>
    );
  };

  const renderUserChoiceList = () => {
    return usersList ? (
      <Autocomplete
        key={Math.random().toString(36).substr(2, 9)}
        noOptionsText="-"
        selectOnFocus
        id="users-list"
        options={usersList}
        getOptionLabel={(option) => option.login}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Sélectionnez un utilisateur"
          />
        )}
        value={
          currentRight !== null && currentRight.idUser !== 0
            ? usersList.find((u) => u.id === currentRight.idUser)
            : null
        }
        onChange={handleChangeSelectedUser}
      />
    ) : (
      <div>
        <br></br>
        <CircularProgress />
      </div>
    );
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioChoice((event.target as HTMLInputElement).value as ListType);
  };

  const renderChoosenList = () => {
    switch (radioChoice) {
      case ListType.Utilisateur:
        return renderUserChoiceList();
      case ListType.Groupe:
        return renderGroupChoiceList();
    }
  };

  const renderChoiceUserOrGroup = () => {
    return (
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Type propriétaire du droit</FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            onChange={handleRadioChange}
            value={radioChoice}
          >
            <FormControlLabel
              value={ListType.Utilisateur}
              control={<Radio color="primary" />}
              label="Utilisateur"
              labelPlacement="start"
              name="radio-button-choice"
            />
            <FormControlLabel
              value={ListType.Groupe}
              control={<Radio color="primary" />}
              label="Groupe"
              labelPlacement="start"
              name="radio-button-choice"
            />
          </RadioGroup>
        </FormControl>
        {renderChoosenList()}
      </div>
    );
  };

  const renderSwitchList = () => {
    switch (rightFor) {
      case RightFor.Entity:
        return renderChoiceUserOrGroup();
      case RightFor.User:
        return renderEntityTypeList();
      case RightFor.Group:
        return renderEntityTypeList();
    }
  };

  return error !== undefined ? (
    <Dialog
      fullWidth={true}
      open={openEditDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{renderSwitchTitle()}</DialogTitle>
      <DialogContent>
        <React.Fragment>{renderSwitchList()}</React.Fragment>
        <hr />
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            control={
              <Checkbox
                name="cancreate"
                onChange={handleChangeRights}
                checked={currentRight?.cancreate}
                color="primary"
              />
            }
            label="cancreate"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="canread"
                onChange={handleChangeRights}
                checked={currentRight?.canread}
                color="primary"
              />
            }
            label="canread"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="canwrite"
                onChange={handleChangeRights}
                checked={currentRight?.canwrite}
                color="primary"
              />
            }
            label="canwrite"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="candelete"
                onChange={handleChangeRights}
                checked={currentRight?.candelete}
                color="primary"
              />
            }
            label="candelete"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="canexport"
                onChange={handleChangeRights}
                checked={currentRight?.canexport}
                color="primary"
              />
            }
            label="canexport"
            labelPlacement="start"
            checked={false}
          />
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                name="canimport"
                onChange={handleChangeRights}
                checked={currentRight?.canimport}
                color="primary"
              />
            }
            label="canimport"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="canexecute"
                onChange={handleChangeRights}
                checked={currentRight?.canexecute}
                color="primary"
              />
            }
            label="canexecute"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="custom1"
                onChange={handleChangeRights}
                checked={currentRight?.custom1}
                color="primary"
              />
            }
            label="custom1"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="custom2"
                onChange={handleChangeRights}
                checked={currentRight?.custom2}
                color="primary"
              />
            }
            label="custom2"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="custom3"
                onChange={handleChangeRights}
                checked={currentRight?.custom3}
                color="primary"
              />
            }
            label="custom3"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="custom4"
                onChange={handleChangeRights}
                checked={currentRight?.custom4}
                color="primary"
              />
            }
            label="custom4"
            labelPlacement="start"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSave} color="primary">
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default RightModal;
