import axios from "axios";
import Cookies from "js-cookie";
import { Method } from "./variables";

const queryApi = (url: string, data: any, method: Method) => {
  const token = Cookies.get("at");

  const options = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url,
    data,
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then(({ data }) => {
        if (data.data !== undefined) {
          resolve(data.data);
        } else {
          resolve(data);
        }
      })
      .catch((error) => reject(error));
  });
};

export default queryApi;
