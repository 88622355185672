import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import TokenProvider from "services/TokenProvider";
import { BrowserRouter } from "react-router-dom";

const {
  REACT_APP_LOGIN_URL,
  REACT_APP_APPLICATION_ID,
  REACT_APP_SSO_URL,
} = process.env;

// if (
//   !Cookies.get("access_token") &&
//   window.location.pathname !== "/login_callback"
// ) {
//   handleLogin();
// } else

ReactDOM.render(
  <TokenProvider
    endpoint={REACT_APP_SSO_URL}
    appId={REACT_APP_APPLICATION_ID}
    redirectUrl={`${REACT_APP_LOGIN_URL}Account/Login?brand_id=${REACT_APP_APPLICATION_ID}`}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </TokenProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
