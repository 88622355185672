import React from "react";
import { Item, Link, List, Title, Wrapper } from "./SubMenu.styles";
import { Item as MenuItem } from "./types";

interface Props {
  isOpen: boolean;
  isHovering: boolean;
  name: string;
  childs: MenuItem[];
  setHover: React.Dispatch<boolean>;
}

const SubMenu = (props: Props) => {
  const closeSubMenu = () => {
    setHover(false);
  };

  const { childs, isOpen, isHovering, name, setHover } = props;

  if (!isOpen && !isHovering) return null;
  return (
    <Wrapper onMouseLeave={closeSubMenu}>
      <Title>{name}</Title>
      <List>
        {childs
          .sort((a, b) => a.order - b.order)
          .map((child) => (
            <Item key={child.id}>
              <Link href={child.url}>{child.name}</Link>
            </Item>
          ))}
      </List>
    </Wrapper>
  );
};

export default SubMenu;
