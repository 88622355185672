import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Box } from "@material-ui/core";
import UserInfos from "modules/UserInfos/UserInfos";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
      zIndex: 989,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "#368cc6",
    },
  })
);

export default function TopBar() {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar>
        <Box display="flexShrink" flexGrow={1} />
        <UserInfos />
      </Toolbar>
    </AppBar>
  );
}
