import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IPerson } from "modules/Interfaces/IPerson";
import queryApi from "hooks/queryApi";
import PersonIcon from "@material-ui/icons/Person";
import { Method } from "hooks/variables";

const Root = styled.div`
  text-align: left;
  display: flex;
`;

const UserName = styled.div`
  color: #fff;
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
  margin-right: 2rem;
  margin-top: 4px;
`;

const Icon = styled.span`
  color: #fff;
  margin-right: 0.375rem;
  padding-right: 5px;
`;

export default function UserInfos() {
  const [person, setPerson] = useState<IPerson | undefined>(undefined);

  useEffect(() => {
    queryApi(
      `${process.env.REACT_APP_API_RIGHTS_URL}api/Person`,
      null,
      Method.Get
    ).then((result: any) => {
      setPerson(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <Icon>
        <PersonIcon />
      </Icon>
      <UserName>
        {person?.firstName} {person?.lastName}
      </UserName>
    </Root>
  );
}
